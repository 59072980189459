import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import decodeToken from "../decodeToken";

const useAuth = () => {
  let isValid;
  const { auth, setAuth, logout } = useContext(AuthContext);

  const { exp } = decodeToken(auth.token, [{ name: "exp" }]);
  if (new Date().getTime() < exp * 1000) {
    isValid = true;
  } else {
    isValid = false;
  }

  const isAuthenticated = auth.token && isValid;
  return {
    auth,
    setAuth,
    exp,
    logout,
    isAuthenticated,
    username: localStorage.getItem("uname"),
  };
};

export default useAuth;
