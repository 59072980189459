import React from "react";
import App from "./App";
import AuthProvider from "./lib/contexts/AuthContext";

export default function Root() {
  return (
    <AuthProvider>
      <div id="platform">
        <App />
      </div>
    </AuthProvider>
  );
}
