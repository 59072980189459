import React, { createContext, useState } from "react";
import devLogger from "../devLogger";

export const AuthContext = createContext();

function AuthProvider({ children }) {
  const [auth, setAuth] = useState({
    token: localStorage.getItem("access_token"),
    username: localStorage.getItem("uname") || "",
  });

  async function logout() {
    const payload = {
      AccessToken: localStorage.getItem("refresh_token"),
      RealmName: localStorage.getItem("realmName"),
      UserId: localStorage.getItem("sub"),
      CompanyId: localStorage.getItem("companyId"),
    };
    if (payload.AccessToken && payload.RealmName) {
      const res = await delApi(
        "/admin/api/authentication/logout",
        "POST",
        payload
      );
    }
    localStorage.clear();
    setAuth({ token: null, username: "" });
  }

  return (
    <AuthContext.Provider value={{ auth, setAuth, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
