import downloadCsv from "./utils/downloadCsv";
import downloadElsx from "./utils/downloadXlsx";
import downloadZip from "./utils/downloadZip";

async function parseFetchResponse(response) {
  const contentType = response.headers.get("content-type");
  const disposition = response.headers.get("content-disposition");
  let filename = null;

  if (contentType) {
    if (contentType.indexOf("application/json") !== -1) {
      return await response.json();
    } else if (contentType.indexOf("text/plain") !== -1)
      return await response.text();
    else if (contentType.indexOf("application/zip") !== -1) {
      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }
      downloadZip(await response.blob(), filename);
    } else if (contentType.indexOf("text/csv") !== -1) {
      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }
      downloadCsv(await response.blob(), filename);
    } else if (
      contentType.indexOf(
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) !== -1
    ) {
      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }
      downloadElsx(await response.blob(), filename);
    } else if (contentType.indexOf("image/jpeg") !== -1) {
      return await response.blob();
    } else if (contentType.indexOf("audio/mpeg") !== -1) {
      const audioBlob = await response.blob();
      if (disposition && disposition.indexOf("inline") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }
      return {
        audioBlob,
        filename,
      };
    }
  }
  return null;
}
export default parseFetchResponse;
