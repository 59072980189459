import parseFetchResponse from "./parseFetchResponse";
import { Notyf } from "notyf";
import { mutate } from "swr";
import { shallowTrimObjectValues } from "./utils/helper";

const SUBKEY = process.env.SUBKEY;
const API_BASE = process.env.API_BASE;
const PREDICTION_ENGIN_API_BASE = process.env.PREDICTION_ENGIN_API_BASE;
const notyf = new Notyf({
  duration: 5000,
  dismissible: true,
  position: { x: "right", y: "top" },
  types: [{ type: "success", background: "hsl(170, 91%, 32%)" }],
});

export async function delApi(
  url,
  method,
  payload,
  successMessage,
  mutateUrl,
  customHeaders = {},
  errorCb,
  isPredictionEnginApi
) {
  let headers = {
    "Ocp-Apim-Subscription-Key": SUBKEY,
    companyName: url.includes("admin/api/company") ? "master" : "",
    "access-origin": "Platform",
    ...customHeaders,
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  };
  const options = { method, headers };
  if (method === "POST" || method === "PATCH" || method === "DELETE") {
    if (!(payload instanceof FormData)) {
      options.headers["Content-Type"] = "application/json";
      options.body = JSON.stringify(shallowTrimObjectValues(payload));
    } else {
      options.body = payload;
    }
  }

  if (url) {
    try {
      const response = await fetch(
        (!isPredictionEnginApi ? API_BASE : PREDICTION_ENGIN_API_BASE) + url,
        options
      );
      const data = await parseFetchResponse(response);

      if (response.ok) {
        successMessage && notyf.success(successMessage);
        mutateUrl && mutate(mutateUrl);
        return data;
      } else if (response.status === 401) {
        var event = new CustomEvent("logoutEvent", {
          bubbles: true,
        });
        document.body.dispatchEvent(event);
      } else {
        if (errorCb) {
          const errorHandled = errorCb(response.status, data, notyf);
          if (errorHandled) {
            return;
          }
        }
        notyf.error(data?.message || "Something went wrong");
      }
    } catch {
      notyf.error("There is no internet connection!");
    }
  } else {
    console.error("No Api URL provided");
  }
}

export default function fetcher(url, customHeaders = {}) {
  return fetch(`${API_BASE}${url}`, {
    headers: {
      "Ocp-Apim-Subscription-Key": SUBKEY,
      companyName: url.includes("admin/api/company") ? "master" : "",
      "access-origin": "Platform",
      ...customHeaders,
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(async (response) => {
      const data = await parseFetchResponse(response);
      if (response.ok) {
        return data;
      } else {
        const error = new Error(data?.message || "Something went wrong");
        error.status = response.status;
        throw error;
      }
    })
    .catch((e) => {
      if (e.status !== 409) {
        if (e.status === 401) {
          var event = new CustomEvent("logoutEvent", {
            bubbles: true,
          });
          document.body.dispatchEvent(event);
        } else if (!window.error_message_is_active) {
          window.error_message_is_active = true;
          if (!e.status) {
            notyf.error("There is no internet connection!");
          } else {
            notyf.error(e.message);
          }
          setTimeout(() => {
            window.error_message_is_active = false;
          }, 5000);
        }
      } else {
        throw e;
      }
    });
}
