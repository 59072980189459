import useUpdateEffect from "../hooks/useUpdateEffect";
import { useCallback, useState, useEffect } from "react";
import moment from "moment";
import { parse } from "mathjs";

export const useCenteredTree = (search, defaultTranslate = { x: 0, y: 0 }) => {
  const [translate, setTranslate] = useState(defaultTranslate);
  const [isInitial, setIsInitial] = useState(false);
  const [zoom] = useState(0.5);

  const resetTranslate = useCallback((val) => {
    setTranslate(val);
  }, []);

  useUpdateEffect(() => {
    if (translate.x == 0) setIsInitial(true);
    else setIsInitial(false);
  }, [translate]);

  const containerRef = useCallback(
    (containerElem) => {
      if (containerElem !== null) {
        const { height } = containerElem.getBoundingClientRect();
        const fullWidth = document.body.clientWidth;
        const fullHeight = document.body.clientHeight;

        if (search) {
          setTranslate({
            x: -search.x + fullWidth / 2 - search.width / 2,
            y: -search.y + fullHeight / 2 + height / 2 - search.height / 2,
          });
          setIsInitial(false);
        } else {
          resetTranslate({ x: 0, y: height / 2 });
        }
      }
    },
    [search, resetTranslate]
  );

  return [
    translate,
    zoom,
    containerRef,
    resetTranslate,
    isInitial,
    setIsInitial,
  ];
};

export function searchTree(element, id) {
  if (element.id === id) {
    return element;
  } else if (element.children != null) {
    var i;
    var result = null;
    for (i = 0; result == null && i < element.children.length; i++) {
      result = searchTree(element.children[i], id);
    }
    return result;
  }
  return null;
}

export function getParent(data, id, ancestor) {
  var node;

  data.some(function (n) {
    if (n.id === id) {
      node = ancestor ? ancestor : null;
      return node;
    }
    if (n.children) {
      return (node = getParent(n.children, id, n));
    }
  });
  return node || null;
}

export function getParentNodeList(nodeId, hierarchy, nodeList) {
  for (const child of hierarchy?.children) {
    if (child.id === nodeId) {
      nodeList.push(child.id);
      return true;
    }
    const containsNode = getParentNodeList(nodeId, child, nodeList);
    if (containsNode) {
      nodeList.push(child.id);
      return true;
    }
  }
}

export function usePathBuilder(data, customFields) {
  const [pathList, setPathList] = useState([]);

  useEffect(() => {
    if (data) {
      const result = [data].map(
        pathBuilder(undefined, undefined, customFields)
      );
      result?.length > 0 && setPathList(result[0]);
    }
  }, [data]);

  return pathList;
}

export function pathBuilder(path, acc = [], customFields) {
  path = path || "";
  return function (o) {
    let pathObj = {
      name: path.concat(
        o.type !== "root" ? " - " + (o.name || o.Name) : o.name || o.Name
      ),
      id: o.id,
    };
    if (customFields?.length) {
      customFields.forEach((x) => {
        if (x === "nodeName") pathObj.nodeName = o.name || o.Name;
        else pathObj[x] = o[x];
      });
    }
    acc.push(pathObj);
    if (o.children?.length > 0) {
      o.children.map(pathBuilder(pathObj.name, acc, customFields));
    }
    return acc;
  };
}

export function usePathIdBuilder(data) {
  const [pathList, setPathList] = useState([]);

  useEffect(() => {
    if (data) {
      const result = [data].map(pathIdBuilder());
      result?.length > 0 && setPathList(result[0]);
    }
  }, [data]);

  return pathList;
}

export function pathIdBuilder(path, acc = []) {
  path = path || "";
  return function (o) {
    let pathObj = {
      name: path.concat(o.type !== "root" ? " - " + o.id : o.id),
      id: o.id,
    };
    acc.push(pathObj);
    if (o.children?.length > 0) {
      o.children.map(pathIdBuilder(pathObj.name, acc));
    }
    return acc;
  };
}

export function getLevelNodeCounts(data, key, level = 0, arr = []) {
  if (data == null) return;

  arr.push({ level, count: data[key]?.length || 0 });

  if (data[key] && data[key].length > 0) {
    data[key].forEach((d) => getLevelNodeCounts(d, key, level + 1, arr));
  }

  var holder = {};

  arr.forEach(function (d) {
    if (holder.hasOwnProperty(d.level)) {
      holder[d.level] = holder[d.level] + d.count;
    } else {
      holder[d.level] = d.count;
    }
  });

  var res = [];

  for (var prop in holder) {
    res.push({ level: +prop, count: holder[prop] });
  }

  return res;
}

export const shallowTrimObjectValues = (obj) => {
  let trimmedPayload = null;
  if (Array.isArray(obj)) {
    trimmedPayload = [];
    trimmedPayload = obj.map((x) => (typeof x == "string" ? x.trim() : x));
  } else if (typeof obj === "object" && obj !== null) {
    const keys = Object.keys(obj);
    trimmedPayload = {};
    keys.forEach(
      (key) =>
        (trimmedPayload[key] =
          typeof obj[key] == "string" ? obj[key].trim() : obj[key])
    );
  } else if (typeof obj === "string") {
    trimmedPayload = obj.trim();
  } else {
    trimmedPayload = obj;
  }
  return trimmedPayload;
};
export const changeImageColor = (image, newColor) => {
  var encoded = image.substring(26);

  // Decode base64
  var decoded = atob(encoded);

  // Create an HTML element from decoded SVG
  var wrapper = document.createElement("div");
  wrapper.innerHTML = decoded.trim();
  var newSvg = wrapper.firstChild;

  // Lookup the <path> and get a ref
  var innerPath = newSvg.getElementsByTagName("path")[0];

  // Set up new color
  innerPath.setAttribute("fill", newColor);
  const svgString = new XMLSerializer().serializeToString(newSvg);
  // console.log({ image: btoa(svgString) });
  return "data:image/svg+xml;base64," + btoa(svgString);
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const hexToDecimal = (rrggbb) => {
  if (rrggbb) {
    let bbggrr =
      rrggbb.substring(5, 7) + rrggbb.substring(3, 5) + rrggbb.substring(1, 3);
    return parseInt(bbggrr, 16);
  }
};

export const decimalToHex = (value) => {
  let bbggrr = ("000000" + value.toString(16)).slice(-6);
  let rrggbb =
    bbggrr.substring(4, 6) + bbggrr.substring(2, 4) + bbggrr.substring(0, 2);
  return "#" + rrggbb;
};

export const checkIfRDPMSApp = () => {
  return (
    sessionStorage.getItem("appName")?.toLowerCase()?.replace(/\s/g, "") ===
    "rdpms"
  );
};

export const checkEndDateGraterThanStartDate = (startDate, endDate) => {
  return moment(
    `2023-05-12 ${startDate.Hr}:${startDate.Min} ${startDate.Meridian}`
  ).isSameOrAfter(
    moment(`2023-05-12 ${endDate.Hr}:${endDate.Min} ${endDate.Meridian}`)
  );
};

export const getTodayAtSpecificHourTest = (timelineStart, timeLineEnd) => {
  const _timelineStart = moment(
    `2023-05-12 ${timelineStart.Hr}:${timelineStart.Min} ${timelineStart.Meridian}`
  );

  const _timeLineEnd = moment(
    `2023-05-12 ${timeLineEnd.Hr}:${timeLineEnd.Min} ${timeLineEnd.Meridian}`
  );

  return (startDate, endDate) => {
    const _startDate = moment(
      `2023-05-12 ${startDate.Hr}:${startDate.Min} ${startDate.Meridian}`
    );
    const _endDate = moment(
      `2023-05-12 ${endDate.Hr}:${endDate.Min} ${endDate.Meridian}`
    );

    if (_startDate.isSameOrAfter(_endDate)) {
      return [
        getTodayAtSpecificHour(
          _startDate.Hr,
          _startDate.Min,
          _startDate.Meridian
        ),
        getTodayAtSpecificHour(
          _endDate.Hr,
          _endDate.Min,
          _endDate.Meridian,
          true
        ),
      ];
    } else {
      return [
        getTodayAtSpecificHour(
          _startDate.Hr,
          _startDate.Min,
          _startDate.Meridian
        ),
        getTodayAtSpecificHour(_endDate.Hr, _endDate.Min, _endDate.Meridian),
      ];
    }
  };
};

export const addPropertyToTree = (tree, propertyName, propertyValue) => {
  // Check if the tree is empty
  if (!tree) {
    return;
  }

  // Add the property to the current node
  tree[propertyName] = propertyValue;

  // Recursively add the property to child nodes
  if (tree.children && tree.children.length > 0) {
    tree.children.forEach((child) => {
      addPropertyToTree(child, propertyName, propertyValue);
    });
  }
};

export const addPropertyToTreeDown = (
  tree,
  targetNodeValue,
  propertyName,
  propertyValue
) => {
  // Check if the tree is empty
  if (!tree) {
    return;
  }

  // Check if the current node matches the target node
  if (tree.value === targetNodeValue) {
    // Add the property to the current node
    addPropertyToTree(tree, propertyName, propertyValue);
    return;
  }

  // Recursively add the property to child nodes
  if (tree.children && tree.children.length > 0) {
    tree.children.forEach((child) => {
      addPropertyToTreeDown(
        child,
        targetNodeValue,
        propertyName,
        propertyValue
      );
    });
  }
};

export const findNodeAndUpdate = (
  tree,
  targetId,
  propertyName,
  propertyValue
) => {
  if (!tree) {
    return null; // Base case: Tree is empty or target not found
  }

  if (tree.value === targetId) {
    tree[propertyName] = propertyValue; // Add or update the boolean property
    return tree; // Base case: Found the target node
  }

  if (Array.isArray(tree.children)) {
    // Recursively search through children nodes
    for (let child of tree.children) {
      const foundNode = findNodeAndUpdate(
        child,
        targetId,
        propertyName,
        propertyValue
      );
      if (foundNode) {
        return foundNode; // Found the target node in a child subtree
      }
    }
  }

  return null; // Target not found in this subtree
};

export const changePropertyName = (obj, oldName, newName) => {
  if (typeof obj !== "object" || obj === null) {
    return obj; // Base case: Return non-object values as is
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => changePropertyName(item, oldName, newName)); // Recursively process each array element
  }

  const newObj = {};
  for (let key in obj) {
    if (key === oldName) {
      newObj[newName] = changePropertyName(obj[key], oldName, newName); // Update property name and process its value recursively
    } else {
      newObj[key] = changePropertyName(obj[key], oldName, newName); // Process other properties recursively
    }
  }

  return newObj;
};

export const findNodeDFS = (root, targetValue, targetProperty = "value") => {
  if (root === null) {
    return null; // Node not found
  }

  if (root[targetProperty] === targetValue) {
    return root; // Node found
  }

  for (const child of root.children) {
    const foundNode = findNodeDFS(child, targetValue, targetProperty);
    if (foundNode !== null) {
      return foundNode; // Node found in the child subtree
    }
  }

  return null; // Node not found
};

// Function to trim a tree until a specific node is found
export const trimTreeUntill = (tree, propertyName, propertyValue) => {
  // Base case: empty tree or target node found
  if (!tree || tree[propertyName] === propertyValue) {
    return { ...tree, children: [] }; // Remove children after the target node
  }

  // Recursively trim children nodes
  const trimmedChildren = tree.children.map((child) =>
    trimTreeUntill(child, propertyName, propertyValue)
  );

  return { ...tree, children: trimmedChildren };
};

//TODO : remove this after confirming newClearError works
export const clearError = (porpName, errorState, setErrorSate) => {
  const _errorState = { ...errorState };
  delete _errorState[porpName];
  setErrorSate(_errorState);
};

export const newClearError = (porpName, setErrorSate) => {
  setErrorSate(({ [porpName]: value, ...rest }) => ({
    ...rest,
  }));
};

// Function to get nodes with a specific name from the tree
export const getNodesByType = (tree, targetType, result = []) => {
  if (tree.type === targetType) {
    result.push(tree);
  }

  if (tree.children && tree.children.length > 0) {
    for (const child of tree.children) {
      getNodesByType(child, targetType, result);
    }
  }

  return result;
};

export const checkAdjecent = (source, adjecent, isLeft) => {
  if (
    source === "Operand" ||
    source === "Constant" ||
    source === "Number" ||
    source === "Colour" ||
    source === "Keyword"
  ) {
    source = "Operand";
  }
  if (
    adjecent === "Operand" ||
    adjecent === "Constant" ||
    adjecent === "Number" ||
    adjecent === "Colour" ||
    adjecent === "Keyword"
  ) {
    adjecent = "Operand";
  }

  if ((source === "OpenBracket", source === "UOperator")) {
    source = "OpenBracket";
  }
  if ((adjecent === "OpenBracket", adjecent === "UOperator")) {
    adjecent = "OpenBracket";
  }
  if (source === "Function" || source === "Conditional") {
    source = "Function";
  }
  if (adjecent === "Function" || adjecent === "Conditional") {
    adjecent = "Function";
  }

  // Dont validate if Semicolon
  if (source === "Semicolon" || adjecent === "Semicolon") {
    return true;
  }

  switch (source) {
    case "Operand":
      return isLeft
        ? ["Operator", "Function", "OpenBracket", "Comma"].includes(adjecent)
          ? true
          : false
        : ["Operator", "CloseBracket", "Comma"].includes(adjecent)
        ? true
        : false;
    case "Operator":
      return isLeft
        ? ["Operand", "Constant", "CloseBracket"].includes(adjecent)
          ? true
          : false
        : ["Operand", "Constant", "OpenBracket", "Function"].includes(adjecent)
        ? true
        : false;
    case "OpenBracket":
      return isLeft
        ? ["OpenBracket", "Function", "Operator", "Comma"].includes(adjecent)
          ? true
          : false
        : [
            "Operand",
            "Constant",
            "CloseBracket",
            "OpenBracket",
            "Function",
          ].includes(adjecent)
        ? true
        : false;
    case "CloseBracket":
      return isLeft
        ? [
            "Operand",
            "Constant",
            "CloseBracket",
            "OpenBracket",
            "Function",
          ].includes(adjecent)
          ? true
          : false
        : ["Operator", "CloseBracket", "Comma"].includes(adjecent)
        ? true
        : false;
    case "Comma":
      return isLeft
        ? ["Operand", "Constant", "CloseBracket"].includes(adjecent)
          ? true
          : false
        : ["Operand", "Constant", "OpenBracket", "Function"].includes(adjecent)
        ? true
        : false;
    case "Function":
      return isLeft
        ? ["OpenBracket", "Function", "Operator", "Comma"].includes(adjecent)
          ? true
          : false
        : [
            "Operand",
            "Constant",
            "CloseBracket",
            "OpenBracket",
            "Function",
          ].includes(adjecent)
        ? true
        : false;
  }
};

export const validateExpression = (
  formulaArray,
  isValid,
  replaceObj,
  notyf
) => {
  const keys = Object.keys(replaceObj);
  const expression = formulaArray.reduce(
    (acc, curr) =>
      `${acc} ${
        keys.find((val) => val === curr.name)
          ? replaceObj[curr.name]
          : curr.name
      }`,
    ""
  );
  try {
    formulaArray.reduce((x, y, index) => {
      if (index != 0) {
        if (!checkAdjecent(x.type, y.type, false) && isValid) {
          isValid = false;
          notyf?.error("Formula Expression is Invalid");
        }
      }
      return y;
    });
    if (!isValid) {
      return isValid;
    }
    let skipParsing = formulaArray.find((item) => item.type === "Semicolon"); //skip parsing as Semicolon is not recognized in parser
    if (!skipParsing) {
      const expressionTree = parse(expression);
      const argsList = [];
      let functionNodes = [];
      expressionTree.traverse((node) => {
        if (node.type === "SymbolNode") {
          if (functionNodes.find((fnNode) => fnNode === node.name)) {
            functionNodes = functionNodes.filter((item) => item !== node.name);
            return;
          }
          argsList.push(node.name);
        }
        if (node.type === "FunctionNode") functionNodes.push(node.name);
      });
    }
  } catch {
    isValid = false;
    notyf?.error("Formula Expression is Invalid");
  }
  return isValid;
};

//Checks if any app in the company has Superset Enabled or EVER had it Enabled at least once
export const hasSupersetApp = () => {
  const applist = JSON.parse(localStorage.getItem("applicationList") || "[]");
  return applist.some(
    (app) =>
      app.SupersetApplication.Enabled || app.SupersetApplication.IsAddedOnce
  );
};

const getCurrentApplication = () => sessionStorage.getItem("appId");

export const hasCustomApp = () => {
  const appId = getCurrentApplication();
  const applist = JSON.parse(localStorage.getItem("applicationList") || "[]");
  const app = applist.find((a) => a.ApplicationId === appId);
  return app?.CustomAppConfig?.Enabled;
};

export const checkIfAppHasFeature = (feature) => {
  const currentApplication = getCurrentApplication();
  return JSON.parse(localStorage.getItem("applicationList") || "[]")
    ?.find((a) => a.ApplicationId === currentApplication)
    ?.Features?.includes(feature);
};

export const createFormulaString = (formulaArray, operandsList, constants) => {
  return formulaArray.reduce((acc, curr) => {
    if (curr.type === "Operand") {
      const operand = operandsList.find((x) => x.Name === curr.name);
      switch (operand?.Type) {
        case "BP":
        case "DP":
          acc += operand?.ParameterName + " ";
          break;
        case "BPA":
        case "DPA":
          if (operand?.AggrMode === "Array") {
            acc += `${operand?.Operation}(${operand?.AggrMode}(${operand?.ParameterName}))#${operand?.AggrDuration} `;
          } else if (
            operand?.AggrMode === "CurrentValue" ||
            operand?.AggrMode === "PreviousValue" ||
            operand?.AggrMode === "CurrMinusPrev"
          ) {
            acc += `${operand?.AggrMode}(${operand?.ParameterName}) `;
          } else {
            acc += `${operand?.AggrMode}(${operand?.ParameterName})#${operand?.AggrDuration} `;
          }
          break;
      }
    } else if (curr.type === "Constant") {
      const constant = constants.find((c) => c.Name === curr.name);
      acc += constant.Value + " ";
    } else if (curr.type === "UOperator") {
      acc += curr.name === "~" ? "! " : `${curr.name} `;
    } else {
      acc += curr.name + " ";
    }
    return acc;
  }, "");
};
