import React, { lazy, Suspense, useEffect, useState } from "react";
import Spinner from "./lib/utils/Spinner";
import useAuth from "./lib/hooks/useAuth";
import "notyf/notyf.min.css";
import NotyfContext from "./lib/contexts/NotyfContext";
import ApplicationProvider from "./lib/contexts/ApplicationContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import fetcher from "./lib/delApi";
import { SWRConfig } from "swr";
const loadAuthenticatedApp = () => import("./AuthenticatedApp");
const AuthenticatedApp = lazy(loadAuthenticatedApp);
// const Login = lazy(() => import("./pages/Login"));

const options = {
  fetcher,
  shouldRetryOnError: false,
  revalidateOnFocus: false,
};

function App() {
  const { isAuthenticated } = useAuth();
  const [notyf, setNotyf] = useState(null);

  useEffect(() => {
    loadAuthenticatedApp();

    import("notyf").then(({ Notyf }) => {
      setNotyf(
        new Notyf({
          duration: 5000,
          dismissible: true,
          position: { x: "right", y: "top" },
          types: [
            { type: "success", background: "hsl(170, 91%, 32%)" },
            {
              type: "warning",
              background: "#ffe827",
              className: "del_warning-toast-wrapper",
            },
          ],
        })
      );
    });
  }, []);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Spinner />}>
        <DndProvider backend={HTML5Backend}>
          <ApplicationProvider>
            <NotyfContext.Provider value={notyf}>
              <SWRConfig value={options}>
                {isAuthenticated && <AuthenticatedApp />}
              </SWRConfig>
            </NotyfContext.Provider>
          </ApplicationProvider>
        </DndProvider>
      </Suspense>
    </ErrorBoundary>
  );
}
export default App;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <p>Something has gone wrong.Please reload.</p>;
    }

    return this.props.children;
  }
}
